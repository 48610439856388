import "./index.styl";

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

// Components
import SocialIcon from "../social_icon/index";

// Assets
import twitter from "../../images/TwitterLogo_white.png";
import youtube from "../../images/YouTube-logo-light.png";
import facebook from "../../images/FB-f-Logo__white_50.png";

class Header extends React.Component {
  state = {
    locked: false,
  }

  componentDidMount() {
     window.addEventListener("scroll", this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }

  onScroll = (event) => {
    const { scrollTop } = event.target.scrollingElement;
    const { locked } = this.state;
    if (!locked && scrollTop > 24) {
      this.setState({ locked: true });
    } else if (locked && scrollTop < 1) {
      this.setState({ locked: false });
    }
  }

  render() {
    const { siteTitle } = this.props;
    const { locked } = this.state;
    let cx = "Header"
    if (locked) {
      cx = cx + " Header--locked";
    }

    return (
      <div className={cx}>
        <div className="Header__Content">
          <h1 className="Header__Content__Title">
            <Link to="/">{siteTitle}</Link>
          </h1>
          <Link to="/" className="Header__Content__Link">Home</Link>
          <Link to="/#about" className="Header__Content__Link">About</Link>
          <Link to="/#music" className="Header__Content__Link">Music</Link>
          <Link to="/#video" className="Header__Content__Link">Video</Link>
          <SocialIcon
            href="https://twitter.com/emilyrosewalsh1"
            src={twitter}
            alt="Link to Emily's Twitter page"
          />
          <SocialIcon
            href="https://www.youtube.com/playlist?list=PLmFdftBnPiQdv0fBjvrimWg_22fVmc7zq"
            src={youtube}
            alt="Link to Emily's YouTube Channel"
          />
          <SocialIcon
            href="https://www.facebook.com/emilyrosewalsh"
            src={facebook}
            alt="Link to Emily's Facebook page"
          />
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
