import "./index.styl";

import React from "react";
import PropTypes from 'prop-types'

function IndexSection({ title, id, hero, children }) {
  let cx = "IndexSection";
  if (hero) {
    cx = cx + " IndexSection--hero";
  }

  return (
    <section className={cx} id={id}>
      <div className="IndexSection__Content">
        {title && <h2 className="IndexSection__Content__Title">{title}</h2>}
        {children}
      </div>
    </section>
  );
}

IndexSection.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  hero: PropTypes.bool,
  children: PropTypes.node,
}

export default IndexSection;