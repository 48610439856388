import "./index.styl";

import React from "react";
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import IndexSection from "../index_section/index";

function AboutSection({ fixedImage }) {
  return (
    <IndexSection
      title="About Emily"
      id="about"
    >
      <div className="AboutSection">
        <Img className="AboutSection__Image" fixed={fixedImage} />
        <div className="AboutSection__Text">
          <p>
            <strong>Emily Rose Walsh</strong> is a coloratura soprano and D.M.A. candidate at the Hartt School of Music in
            West Hartford, Connecticut.  Her lyrical voice and expansive range have enabled her to perform
            repertoire by composers as diverse as Couperin and Strauss, while her command of music history
            has inspired compelling programs for audiences largely unfamiliar with art song.  Recitals have
            included Berlioz’s <span className="AboutSection__Text__Work">Les nuits d’été</span>,
            Britten’s <span className="AboutSection__Text__Work">On This Island</span>,
            Debussy’s <span className="AboutSection__Text__Work">Quatre Chansons de Jeunesse</span>, and
            intensive studies of songs by Schubert, Dowland, and Poulenc.  Her mission to present classical music as an
            accessible art form is inspired by her work with the late Maestro Charles Ansbacher, who devoted his career
            to public outreach through informative performances of orchestral works.
          </p>
          <p>
            Following the completion of her master’s degree, Emily attended the Franco-American Vocal Academy
            in Paris and Périgueux, France.  There, she appeared as Parthénis
            in <span className="AboutSection__Text__Work">La Belle Hélène</span>, Eurydice in
            the "Fly Duet" from <span className="AboutSection__Text__Work">Orphée aux Enfers</span>,
            and in numerous recitals.  She recalls with particular fondness a French song recital in the village
            of Génis, and the warm hospitality of its residents.  As both a passionate interpreter of Mozart
            and advocate for audience outreach, she has appeared several times as the Queen of the Night in
            Boston College family concerts.
          </p>
          <p>
            As a doctoral candidate, Emily has distinguished herself as a strong writer and historical
            analyst.  Additionally, she has presented two lectures on vocal music: one on varied composers’
            interpretations of the Goethe text "Kennst du das Land," and another on songs written by Poulenc
            during the era of the Nazi Occupation.
          </p>
          <p>
            In addition to her career in music, Emily has worked for over a decade in the nonprofit sector,
            building expertise in operations, project management, and copy editing.  Having served both a
            grassroots orchestra and a strategy consulting firm, she understands the critical role of philanthropy
            in the arts, as well as in education, social mobility, and civil rights.  Among her editorial
            achievements is the 17,000-word article, "How Philanthropic Collaborations Succeed, and Why They
            Fail," published by the <span className="AboutSection__Text__Work">Stanford Social Innovation Review</span>,
            available <a href="https://ssir.org/articles/entry/how_philanthropic_collaborations_succeed_and_why_they_fail#email">
            here</a>.
          </p>
          <p>
            A West Hartford native, Emily earned her bachelor’s degree from Boston College and her master’s
            from the Longy School of Music in Cambridge, Massachusetts.  She studies privately with Dr. Michelle
            Fiertek, and is the soprano section leader at St. Peter Claver Church in West Hartford under the
            direction of esteemed conductor Tim Stella.
          </p>
        </div>
      </div>
    </IndexSection>
  );
}

const AboutSectionWithImage = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "headshot.jpg" }) {
          childImageSharp {
            fixed(width: 360) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={data => <AboutSection fixedImage={data.placeholderImage.childImageSharp.fixed} />}
  />
)

export default AboutSectionWithImage
