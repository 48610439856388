import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

import Headshot from '../components/headshot/index';
import AboutSection from '../components/about/index';
import MusicSection from '../components/music/index';
import VideoSection from '../components/video/index';

const IndexPage = () => (
  <Layout>
    <SEO
      title="Emily Rose Walsh, Soprano"
      keywords={[
        `emily rose walsh`,
        `emily walsh`,
        `soprano`,
        `colorutura`,
        `singer`,
        `west hartford`,
        `connecticut`,
      ]}
    />

    <Headshot />
    <AboutSection />
    <MusicSection />
    <VideoSection />
  </Layout>
)

export default IndexPage
