import "./index.styl";

import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import IndexSection from "../index_section/index";

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.app/gatsby-image
 * - `StaticQuery`: https://gatsby.app/staticquery
 */

const Headshot = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "jumbo1_1.png" }) {
          childImageSharp {
            fluid(maxWidth: 1170) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <IndexSection
        id="headshot"
        hero
      >
        <div className="Headshot">
          <Img className="Headshot__Image" fluid={data.placeholderImage.childImageSharp.fluid} />
          <div className="Headshot__Headline">
            <strong>Emily Rose Walsh</strong> is a coloratura soprano based in West Hartford, Connecticut.<br />
            She is dedicated to reaching new audiences through educational, interactive performances.
          </div>
        </div>
      </IndexSection>
    )}
  />
)
export default Headshot
