import "./index.styl";

import React from "react";

import IndexSection from "../index_section/index";

import Clip from "./clip";

const clips = [
  {id: "jQMrRBnZYlE", title: "Fly Duet from Orphée aux Enfers (Offenbach)"},
  {id: "fIEnps5LIMk", title: "Valse Tyrolienne (Offenbach)"},
  {id: "viM03Q4J04k", title: "C (Poulenc)"},
  {id: "0mutWhcCo0c", title: "Apparition (Debussy)"},
  {id: "iPZ3lV88iRQ", title: "Soleil, fuis de ces lieux (Rameau)"},
  {id: "nfqpNO-icJw", title: "Le Papillon et La Fleur (Fauré)"},
];

export default function VideoSection() {
  return (
    <IndexSection
      title="Video"
      id="video"
    >
      <div className="VideoSection">
        {clips.map(clip => <Clip key={clip.id} clip={clip} />)}
      </div>
    </IndexSection>
  );
}
