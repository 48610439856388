import "./track.styl";

import React from "react";
import PropTypes from "prop-types";

import ExpandableRow from "../expandable_row/index";

function Track({ track }) {
  return (
    <ExpandableRow
      title={track.title}
    >
      <div className="Track">
        <iframe
          title={track.title}
          width="100%"
          height="166"
          scrolling="no"
          frameBorder="no"
          src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${track.id}` +
               `&amp;color=b0cee4&amp;auto_play=false&amp;buying=false&amp;liking=false&amp;download=false&amp;sharing=false&amp;` +
               `show_artwork=false&amp;hide_related=false&amp;show_comments=false&amp;show_user=false&amp;show_reposts=false`}
        />
        <p>Recorded live on {track.recordedOn}</p>
      </div>
    </ExpandableRow>
  );
}

Track.propTypes = {
  track: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    recordedOn: PropTypes.string.isRequired,
  }).isRequired,
}

export default Track;
