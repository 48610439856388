import "./index.styl";

import React from "react";
import PropTypes from "prop-types";

class ExpandableRow extends React.Component {
  state = {
    expanded: false,
  }

  toggleExpanded = () => {
    this.setState(prevState => ({
      expanded: !prevState.expanded,
    }));
  }

  render() {
    const { expanded } = this.state;
    const { title, children } = this.props;

    let bodyCx = `ExpandableRow__Body`;
    if (expanded) {
      bodyCx = `${bodyCx} ${bodyCx}--expanded`;
    }

    return (
      <div className="ExpandableRow">
        <h4 className="ExpandableRow__Header" onClick={this.toggleExpanded}>
          <div className="ExpandableRow__Header__Title">
            {title}
          </div>
          {expanded && (
            <div className="ExpandableRow__Header__CloseButton">&#x2715;</div>
          )}
        </h4>
        <div className={bodyCx}>
          {children}
        </div>
        {expanded && (
          <div className="ExpandableRow__Collapser" onClick={this.toggleExpanded}>
            &#8689;
          </div>
        )}
      </div>
    );
  }
}

ExpandableRow.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
}

export default ExpandableRow;
