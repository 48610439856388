import "./index.styl";

import React from "react";

import IndexSection from "../index_section/index";

import Track from "./track";

const tracks = [
  {id: 267714242, title: "The Queen's Epicedium, Henry Purcell", recordedOn: "March 16, 2015"},
  {id: 267714241, title: "On This Island - Seascape, Benjamin Britten", recordedOn: "March 16, 2015"},
  {id: 267714239, title: "Nachtstuck, Franz Schubert", recordedOn: "March 16, 2015"},
  {id: 267714238, title: "Quatre Chansons de Ronsard - A Cupidon, Darius Milhaud", recordedOn: "March 16, 2015"},
  {id: 267210344, title: "Suleika I, Franz Schubert", recordedOn: "October 6, 2013"},
  {id: 267210343, title: "Twelve Poems of Emily Dickinson: 9. I Felt a Funeral in my Brain, Aaron Copland", recordedOn: "October 6, 2013"},
  {id: 267210342, title: "Duex Poemes de Louis Aragon - C, Francis Poulenc", recordedOn: "October 6, 2013"},
  {id: 267210340, title: "In Stiller Nacht, Johannes Brahms", recordedOn: "April 23, 2011"},
];

export default function MusicSection() {
  return (
    <IndexSection
      title="Music"
      id="music"
    >
      <div className="MusicSection">
        {tracks.map(track => <Track key={track.id} track={track} />)}
      </div>
    </IndexSection>
  );
}
