import "./clip.styl";

import React from "react";
import PropTypes from "prop-types";

import ExpandableRow from "../expandable_row/index";

function Clip({ clip }) {
  return (
    <ExpandableRow
      title={clip.title}
    >
      <div className="Clip">
        <div className="Clip__FrameWrapper">
          <iframe
            title={clip.title}
            src={`https://www.youtube.com/embed/${clip.id}`}
            allowFullScreen
          />
        </div>
      </div>
    </ExpandableRow>
  );
}

Clip.propTypes = {
  clip: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
}

export default Clip;
